export const char2Unicode = (char) => {
  if (char.length === 1) {
    return 'U+' + char.charCodeAt(0).toString(16).toUpperCase();
  }
  const high = char.charCodeAt(0);
  const low = char.charCodeAt(1);
  return (
    'U+' +
    ((high - 0xd800) * 0x400 + low - 0xdc00 + 0x10000)
      .toString(16)
      .toUpperCase()
  );
};

export const unicode2Char = (unicode) =>
  String.fromCharCode(parseInt(unicode.substr(2), 16));

const cutStr = (str, start, length) => {
  let copy = '';
  for (let i = 0; i < str.length; i++) {
    if ((i < start || i >= start + length) && str[i] !== undefined) {
      copy += str[i];
    }
  }
  return copy;
};

const extractCnStr = (str, prev) => {
  let match = str.match(/%F0/);
  let current = prev;

  if (match === null) {
    return current.concat(decodeURI(str).split(''));
  } else {
    let newStr = decodeURI(str.slice(match.index, match.index + 12));
    current.push(newStr);
    return extractCnStr(cutStr(str, match.index, 12), current);
  }
};

export const extractChars = (str) => {
  const regex = /\p{Script=Han}/gu;
  const matches = Array.from(str.matchAll(regex), m => m[0]);

  return matches;
};

export const extractUniqueChars = (str) => {
  const matches = extractChars(str);
  // remove duplicates, while retaining order
  const uniqueMatches = [];
  const unique = new Set();
  for (const match of matches) {
    if (!unique.has(match)) {
      unique.add(match);
      uniqueMatches.push(match);
    }
  }

  return uniqueMatches;
}

