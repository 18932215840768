import { request } from '@umijs/max';

export const getGraph = async (params, options) => {
  const { char, use_cache } = params;
  if (char === undefined || char.length === 0) {
    return {
      xys: [],
      edges: [],
    };
  }

  const key = `${params.char}-graph`;
  let cache = window.sessionStorage.getItem(key);
  if (!cache || use_cache === false) {
    const res = await request(`/api/resource/get_graph`, {
      method: 'POST',
      data: {
        ...params,
      },
      ...(options || {}),
    });
    window.sessionStorage.setItem(key, JSON.stringify(res));
    cache = JSON.stringify(res);
  }

  const cache_obj = JSON.parse(cache);
  const xys_str = cache_obj[0];
  const edges_str = cache_obj[1];

  let xys = [];
  let edges = [];

  xys_str.split(';').forEach((str) => {
    const [x, y] = str.split(',');
    xys.push([parseInt(x), parseInt(y)]);
  });

  edges_str.split(';').forEach((str) => {
    const [n1, n2] = str.split(',');
    edges.push([parseInt(n1), parseInt(n2)]);
  });
  const graph = { xys, edges };
  return new Promise((resolve, reject) => {
    resolve(graph);
  });
};

export const getGraphs = async (params, options) => {
  return request(`/api/resource/get_graphs`, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });

};

export const updateGraph = async (params, options) => {
  return request(`/api/resource/update_graph`, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const hashStruct = async (params, options) => {
  return request(`/api/resource/hash_struct`, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getAllChars = async (params, options) => {
  return request(`/api/resource/get_all_characters`, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
