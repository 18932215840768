import { request } from '@umijs/max';

export const getStats = async (params, options) => {
  const url = '/api/resource/get_stats';
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getRecords = async (params, options) => {
  const page = params.page;
  const url = '/api/resource/get_records' + '?page=' + page;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getAllRecords = async (params, options) => {
  const url = '/api/resource/get_all_records';
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getAllRecordsHistory = async (params, options) => {
  const url = '/api/resource/get_all_records_history';
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};


export const getDef = async (params, options) => {
  const char = params.char;
  const def = sessionStorage.getItem(char + '-def');
  if (def) {
    return Promise.resolve(def);
  }
  const url = '/api/resource/get_def';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getDefs = async (params, options) => {
  const url = '/api/resource/get_defs';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getPinyin = async (params, options) => {
  const char = params.char;
  const def = sessionStorage.getItem(char + '-pinyin');
  if (def) {
    return Promise.resolve(def);
  }
  const url = '/api/resource/get_pinyin';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getPinyins = async (params, options) => {
  const url = '/api/resource/get_pinyins';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const definitionSearch = async (params, options) => {
  const url = '/api/resource/definitionSearch';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getAllBackup = async (params, options) => {
  const url = '/api/resource/get_all_backup';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const backup = async (params, options) => {
  const url = '/api/resource/backup';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const loadBackup = async (params, options) => {
  const url = '/api/resource/load_backup';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const deleteBackup = async (params, options) => {
  const url = '/api/resource/delete_backup';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const downloadBackup = async (params, options) => {
  const url = '/api/resource/download_backup';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};