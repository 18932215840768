import user from "@/models/user";
import { register } from "@/services/user";
import create from "@ant-design/icons/lib/components/IconFont";

export default {
    user: {
        lang: '中文',
        lang_success: '语言更新成功',
        lang_fail: '语言更新失败',
        login: 'Login',
        logout: 'Logout',
        clear: 'Clear',
        signup: 'Sign Up',
        username: 'Username',
        username_or_email: 'Username or Email',
        password: 'Password',
        email: 'Email',
        invalid_email: 'Invalid email',
        agreement: 'Agreement',
        termOfService: 'I have read and agree to the terms of service',
        please_agree_to: 'Please agree to the terms of service',
        register: 'Register',
        forgot_pwd: 'Forgot password',
        getValidationCodeSuccess: 'Get validation code success',
        resetPwdSuccess: 'Reset password success',
        resetPwd: 'Reset password',
        emailOrUsernameEmptyError: 'Email or username cannot be empty',
        emailFormatError: 'Invalid email format',
        username_or_email: 'Username or Email',
        getValidationCode: 'Get validation code',
        validationCode: 'Validation code',
        newPassword: 'New password',
    },
    home: {
        copy_record_id: 'Copy record ID',
        copy_success: 'Copy success',
        ori_idea: 'Original idea',
        JB: 'Jennifer Ball',
        impl: 'Implementation',
        XC: 'Xu Chao',
        characters: 'Characters',
        structures: 'Structures',
        users: 'Users',
        walkthrough: 'Website walkthrough',
        intro: ' Enter a Hanzi character that contains the structure you are interested in, or English word(s), or select a structure from the list below.',
        structLib: 'Structure library',
        example_char: 'Hanzi/semantic keyword',
        example_fail: 'Invalid input, please input one Chinese character with the target structure you are interested in.',
        load_more: "Load more",
        structures_in_lib: 'Structures in database',
        structure_not_found: "If you don't find the target structure, click here",
        text_search_result: 'Text search result',
    },
    create: {
        title: 'Define the target substructure by clicking the lines',
        check_db: 'Check database',
        similar_structures: 'Similar structures',
        no_hit: 'No hit, create a new record'
    },
    record: {
        related_structures: 'Related structures',
        no_data: 'No data',
        desp1: 'Characters are organized by meaning. Characters with no definitions are at the end.',
        desp2: 'If you want to search for a target structure, or edit the current result, please click the `Edit` icon on the right.',
        edit: 'Edit record',
        copy_to_excel: 'Copy to clipboard with Excel format',
        save_to_excel: 'Save to excel',
        compare_characters: 'Compare two groups of characters',
        groupA: 'Group A',
        groupB: 'Group B',
        common: 'Common',
        uniqueA: 'Unique in A',
        uniqueB: 'Unique in B',
        duplicateA: 'Duplicate in A',
        duplicateB: 'Duplicate in B',
        merged: 'Merged',
        search: 'Search',
        search_placeholder: 'Text search for character and definition',
        search_success: 'Search success',
        rollback: 'Clear search, rollback to full record',
        edit: 'Edit record',
        compare: 'Compare two groups of characters',
        scroll_to_top: 'Scroll to top',
        scroll_to_bottom: 'Scroll to bottom',
    },
    workspace: {
        edit_structure: 'Edit Structure',
        clear_all_selected_edges: 'Clear all selected edges',
        clear_invalid_edges: 'Clear all invalid edges (dash lines)',
        toggle_edges_region: 'Toggle edges in region by dragging/clicking',
        load_success: 'Successfully load',
        save_success: 'Successfully save',
        sort_def_by_alphabet: 'Sort definitions by alphabet',
        sort_def_by_alphabet_success: 'Successfully sort definitions by alphabet',
        info: "Info",
        email: "Contact us",

        instruct: {
            guidance: 'Guidance',
            i1: "Change target structure by selecting or deselecting the red lines",
            i2: "Click `Search` button to start; it takes 5~8 mins to finish",
            i3: "Once the search is done, you can select `Unclassified` to check the results",
            i4: "When you find results you want to keep, select them, choose the `Selected` tab, then select  `Move selected to,` and choose `Positive`",
            i5: "The final step is to save the positive results to a .tsv file under the `Save/Load` tab. You can open them in Excel or Numbers. If you are importing them into Excel, make sure the file origin is set to `Unicode (UTF-8)`",
            i6: "You can save or load your progress at any time by clicking the `Save` or `Load` button under the `Save/Load` tab"
        },
        font:{
            test: "Font test",
            type: "Char type",
            your_browser: "Your browser",
            test_desp:"If you see squares or blank spaces in the table above, your current system does not support these characters, you will need to download and install a font such as ",
            install:"Font install",
            install_1:"Download and save the file ",
            install_2:"Open the hanazono-20170904.zip archive folder",
            install_3:"Double-click on the HanaMinA.ttf file",
            install_4:"Click the Install button",
            install_5:"Double-click on the HanaMinB.ttf file",
            install_6:"Click the Install button",
            install_7:"You may need to restart your web browser (and in some cases, restart your operating system) for the changes to take effect",
            install_8:"Come back and make sure that everything is rendered properly",
        },
        search: {
            paste: 'Put character(s) here to see their data',
            promote: 'characters to promote',
            select_these: 'Select these characters',
            remove_these: 'Remove these characters',
            target: 'Target',
            alter_structure: 'Please provide the substructure to compare with on the left.',
            select_page: 'Select page',
            deselect_page: 'Deselect page',
            select_group: 'Select group',
            deselect_group: 'Deselect group',
            total: 'Total',
            chars: ' characters',
            move_selected: 'Move selected to',

            advanced_setting: 'Advanced setting',
            bw: 'Beam width',
            bwi: 'Size of search space. 0 means dynamic adjustment.',
            pt: 'Polarization threshold',
            pti: 'Tolerance of direction error for new node.',
            st: 'Skeleton threshold',
            sti: 'Tolerance of connection (skeleton) error for new proposal.',
            post: 'Posture threshold',
            posti: 'Tolerance of posture error for new proposal.',
            sm: 'Search mode',
            smi_highlighted: 'Search on the highlighted substructure.',
            smi_whole: 'Search on the whole graph of each character.',
            smi: 'Search on the highlighted substructure or the whole graph of each character.',
            load_preset: 'Load preset parameters (Strict is the default)',
            strick: 'Strict',
            normal: 'Normal',
            loose: 'Loose',
            sort_param: 'Sort parameters',
            nw: 'Node weight',
            nwi: 'Portion of node branching difference in sorting.',
            sw: 'Skeleton weight',
            swi: 'Portion of connectivity difference in sorting.',
            pw: 'Posture weight',
            pwi: 'Portion of posture difference in sorting.',
            resort: 'Resort',
            search_first: 'Please search first',
        },
        task: {
            info: 'Task information',
            create_fail: 'Failed to create task: ',
            wait_in_line: 'Waiting in line, ',
            task_before: ' tasks before you',
            running: 'Running',
            fetching: 'Fetching results...',
            done: 'Done, you can check the result or change weights to resort now!',
            unknown: 'Unknown state',
            time_info: 'This process may take 10~15 mins, feel free to come back later.'
        },
        commit:{
            commit_success: 'Successfully committed',
            commit_msg: 'Commit message',
            admin_ops: 'Admin operations',
        },
        sl: {
            push_latest: 'Push as latest',
            push_success: 'Successfully pushed as latest',
            push_fail: 'Failed to push as latest',
            load_json: 'Load record json',
            save_json: 'Save record json',
            save_to_excel: 'Save Positive and Undefined to Excel',
        },
    },
    common: {
        positive: 'Positive',
        undefined: 'Undefined',
        unclassified: 'Unclassified',
        operation: 'Operation',
        search: 'Search',
        selected: 'Selected',
        commit: 'Commit',
        save_and_load: 'Save/load',
        login_first_parentheses: '(please login first)',
        copy_all_excel: 'Copy all for Excel',
    },

    about_us: {
        j1: "Jennifer Ball (or 包弫—Bao1 Zhen3—弫 means \“impactful, powerful\”)\
              has a Bachelor’s in Fine Arts in Theater from UCLA and an MFA in\
              Creative Writing from San Diego State University. Jennifer has\
              taught English to 453 Soochow University students in Suzhou, China\
              since 2013. She has taken every student out to dinner, plus taken\
              many to the US and to Spain. She has facilitated swimming lessons\
              for some of her students, several of whom now know how to swim.",
        j2: "Jennifer has also given away 600 free bicycle helmets to Soochow\
              students in an effort to promote safety. She started studying\
              written Chinese and Mandarin in 2009 (8+ years) due to her\
              hypothesis that female mammals are the root of written language.\
              Her website www. OriginofAlphabet.com explores linguistic data of\
              early cultures. Jennifer thinks Hanzi is the key to all written\
              languages. Jennifer hired former student Xu Chao to build her a\
              substructure search engine of Hanzi, which Xu Chao did in one\
              year. They are now building the website for the search engine,\
              giving talks, and submitting their papers to journals.",
        j3: "Jennifer has played French horn, trumpet, piano, and sung in rock\
              bands with her husband, Dr. Michael Varney (head of Genentech’s\
              Research and Development), since 1985. They have played in the\
              Bonedells, the Fluids, the Free-Range Chickens, and now the\
              Fingerpuppets, which won the Genentech Battle of the Bands in\
              2009. Jennifer has been on six television game shows and a\
              documentary about game shows. Together, Jennifer and Mike have\
              produced a daughter, Jordan, who graduated from Harvey Mudd in\
              computer science and is currently getting a Ph.D. in Psychology at\
              UC Davis, along with studying Arabic on the side. Dalton, their\
              son, graduated from Pitzer with a computer science degree from\
              Harvey Mudd and is getting an MBA at UC San Diego, as well as\
              playing bass in the rock band Forty Feet North.",
        x1: "Xu Chao has a Bachelor’s degree in Physics from the College of\
              Nano Science and Technology at Soochow University, Suzhou,\
              China. After graduation, he spent one and half years researching\
              organic memcapacitors, then his interest turned to computer\
              programming and he started to work with Jennifer Ball,\
              developing HanziFinder: a substructure search engine for Chinese\
              characters.",
    },


    termOfService: {
        title: 'HanziFinder Terms of Service Agreement',
        last_update: 'Last Updated: November 11, 2023',
        t1: "Acceptance of Terms",
        p1: "By accessing or using HanziFinder (the \"Service\"), you agree to comply with and be bound by the following Terms of Service Agreement (\"Agreement\"). If you do not agree to these terms, please do not use the Service. This Agreement is a legally binding contract between you and HanziFinder.",
        t2: "Changes to the Agreement",
        p2: "HanziFinder reserves the right to modify or amend this Agreement at any time without prior notice. You are responsible for regularly reviewing this Agreement for any changes. Your continued use of the Service following the posting of changes constitutes your acceptance of those changes.",
        t3: "Description of Service",
        p3: "HanziFinder is a Hanzi substructure search engine that allows users to search for and analyze Chinese characters and text based on their structural components and properties.",
        t4: "User Registration",
        p4: "While some features of HanziFinder may be accessible without registration, certain services or features may require user registration. When registering, you agree to provide accurate and current information and to maintain the security of your account credentials. You are responsible for all activities that occur under your account.",
        t5: "User Conduct",
        p5: "You agree to use HanziFinder in compliance with all applicable laws and regulations. You agree not to use HanziFinder for any illegal or unauthorized purpose. You agree not to use HanziFinder to violate any laws in your jurisdiction (including but not limited to copyright laws).",
        t6: "Intellectual Property Rights",
        p6: "HanziFinder and its content, including but not limited to text, graphics, logos, and software, are protected by copyright and other intellectual property laws. You may not copy, reproduce, distribute, or create derivative works based on HanziFinder without our explicit permission.",
        t7: "Disclaimer of Warranties",
        p7: "HanziFinder is provided \"as is\" and \"as available\" without warranties of any kind, whether express or implied. We do not warrant that the Service will be error-free or uninterrupted or that it will meet your specific requirements.",
        t8: "Limitation of Liability",
        p8: "To the fullest extent permitted by law, HanziFinder and its affiliates shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use of the Service or this Agreement.",
        t9: "Termination",
        p9: "HanziFinder reserves the right to terminate or suspend your access to the Service, with or without cause, at any time and without notice.",
        t10: "Governing Law",
        p10: "This Agreement shall be governed by and construed in accordance with the laws of California.",
        t11: "Contact Information",
        p11: "If you have any questions or concerns regarding this Agreement, please contact us at admin@hanzifinder.com. By using HanziFinder, you agree to abide by this Terms of Service Agreement. If you do not agree to these terms, please refrain from using the Service.",
    }

};
