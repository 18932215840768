import { request } from '@umijs/max';

export const login = async (params, options) => {
  return request('/api/user/login', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const register = async (params, options) => {
  return request('/api/user/register', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getValidationCode = async (params, options) => {
  return request('/api/user/get_validation_code', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const resetPwd = async (params, options) => {
  return request('/api/user/reset_pwd', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const updateLanguage = async (params, options) => {
  return request('/api/user/update_language', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getLanguage = async (params, options) => {
  return request('/api/user/get_language', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
