import { request } from '@umijs/max';

export const char2records = async (params, options) => {
  return request('/api/search/char2records', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const similarStructures = async (params, options) => {
  return request('/api/search/similar_structures', {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
