import { useMemo } from 'react';
import { Stage, Layer, Circle, Rect, Line } from 'react-konva';
import { Color, NodeRadius, EdgeWidth } from '@/constants/graph';
import * as constants from '@/constants/graph';

const Structure = ({ struct, size = 150, color = Color.selected }) => {
  const { xys: _xys, edges } = struct;
  const xys = _xys.map(([x, y]) => [x, constants.MaxH - y]);
  const newXys = useMemo(() => {
    const margin = 10;

    const maxX = Math.max(...xys.map(([x, y]) => x));
    const minX = Math.min(...xys.map(([x, y]) => x));
    const maxY = Math.max(...xys.map(([x, y]) => y));
    const minY = Math.min(...xys.map(([x, y]) => y));
    const xRange = maxX - minX;
    const yRange = maxY - minY;

    const canvasXRange = size - margin * 2;
    const canvasYRange = size - margin * 2;
    const xRatio = canvasXRange / xRange;
    const yRatio = canvasYRange / yRange;

    const ratio = Math.min(xRatio, yRatio);

    const xShift = (canvasXRange - xRange * ratio) / 2 + margin;
    const yShift = (canvasYRange - yRange * ratio) / 2 + margin;

    return xys.map(([x, y]) => {
      const newX = (x - minX) * ratio + xShift;
      const newY = (y - minY) * ratio + yShift;
      return [newX, newY];
    });
  }, [xys]);

  const edgesCanvas = edges.map(([n1, n2], idx) => {
    const [x1, y1] = newXys[n1];
    const [x2, y2] = newXys[n2];

    return (
      <Line
        key={`edge_${idx}`}
        points={[x1, y1, (x1 + x2) / 2, (y1 + y2) / 2, x2, y2]}
        stroke={color}
        fill={color}
        strokeWidth={EdgeWidth}
      />
    );
  });

  const nodes = newXys.map(([x, y], idx) => {
    return (
      <Circle
        key={`node_${idx}`}
        id={`node_${idx}`}
        x={x}
        y={y}
        radius={NodeRadius}
        fill={color}
      />
    );
  });

  return (
    <div style={{ width: size, height: size, border: '1px solid', pointerEvents: 'none' }}>
      <Stage height={size - 2} width={size - 2}>
        <Layer>
          <Rect x={0} y={0} width={size} height={size} fill={Color.canvas} />
          {edgesCanvas}
          {nodes}
        </Layer>
      </Stage>
    </div>
  );
};

export default Structure;
