import { request } from '@umijs/max';

export const getGraph = async (params, options) => {
  // const graph = window.sessionStorage.getItem(`${params.char}-graph`);
  const graph = null;
  if (graph) {
    return new Promise((resolve, reject) => {
      resolve(JSON.parse(graph));
    });
  } else {
    const char = params.char;
    if (char === undefined || char.length === 0) {
      return {
        xys: [],
        edges: [],
      };
    }

    return request(`/api/resource/get_graph`, {
      method: 'POST',
      data: {
        ...params,
      },
      ...(options || {}),
    });
  }
};

export const updateGraph = async (params, options) => {
  return request(`/api/resource/update_graph`, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const hashStruct = async (params, options) => {
  return request(`/api/resource/hash_struct`, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getAllChars = async (params, options) => {
  return request(`/api/resource/get_all_characters`, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
