import React, { useState, useEffect } from 'react';
import { Row, Input, Modal, List, Button, message, Divider } from 'antd';
import VirtualList from 'rc-virtual-list';


import * as fontConst from '@/constants/font';
import * as searchAPI from '@/services/search';
import * as resourceAPI from '@/services/resource';
import * as charUtils from '@/utils/character';
import { Link, useIntl, history, useSelector, getLocale } from '@umijs/max';

import Structure from '@/components/Structure';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import LoadingBar from 'react-top-loading-bar';

import './SearchBar.less';
import Highlighter from "react-highlight-words";
import api from '@/services';

import useWindowSize from '@/hooks/useWindowSize';
import * as randomUtils from '@/utils/random';

const SearchInput = Input.Search;
import { FontFamily } from '@/constants/font';

const StructItem = ({ item, onItemClick, size }) => {

  const intl = useIntl();
  const msg = intl.messages;

  const [messageApi, contextHolder] = message.useMessage();

  const uuid = 'home-struct-' + randomUtils.makeId(8);
  const { show } = useContextMenu({
    id: uuid,
  });

  const [struct, setStruct] = useState({
    xys: [],
    edges: [],
  });

  const getStruct = async () => {
    if (item.record_id === '' || !item.record_id) {
      setStruct({
        xys: [],
        edges: [],
      });
      return;
    }
    const res = await api.record.getRecordStruct({ record_id: item.record_id });
    setStruct(res);
  };

  useEffect(() => {
    getStruct();
  }, [item]);


  return (
    <div
      onClick={(e) => {
        e.nativeEvent.stopImmediatePropagation();
        if (onItemClick) {
          onItemClick();
        }
        history.push(`/record?id=${item.record_id}`);
      }}
    >
      {contextHolder}
      <div id={uuid}
        onContextMenu={(event) => {
          event.preventDefault();
          show({
            event,
            props: {
              key: 'value',
            },
          })
        }}>
        <a
          href={`/record?id=${item.record_id}`}
          onClick={(e) => e.preventDefault()}
        >
          <Structure struct={struct} size={size} />
        </a>

      </div>
      <Menu id={uuid}>
        <Item onClick={(e) => {
          navigator.clipboard.writeText(item.record_id);
          messageApi.success(msg['home.copy_success'] + ': ' + item.record_id, 1.5);
        }}>
          <div
            style={{ zIndex: 0 }}
          >
            {msg['home.copy_record_id']}
          </div>
        </Item>
        <Item onClick={() => {
          navigator.clipboard.writeText(window.location.origin + '/record?id=' + item.record_id);
          messageApi.success(msg['home.copy_success'] + ': ' + window.location.origin + '/record?id=' + item.record_id,
            1.5);
        }}>
          {msg['home.copy_record_link']}
        </Item>
      </Menu>
      {item.num !== undefined ?
        (<div
          style={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 8,
            fontSize: 10,
          }}
        >
          <span>{item.num}</span>
        </div>) : null
      }

    </div>
  )
};


const StructModal = ({ open,
  char, data, exampleData, textSearchResult, query,
  gutter = 8, size, onCancel }) => {
  const intl = useIntl();
  const msg = intl.messages;

  const lang = getLocale().toLowerCase();
  const is_zh = lang.includes('zh');

  const { isMobile } = useWindowSize();

  const initResult = Object.keys(exampleData).length > 0 ? [exampleData] : [];
  const result = [...initResult, ...textSearchResult];

  const copyResultToClipboard = () => {
    let t = '';
    result.forEach((item, index) => {
      t += `${index + 1} ${item.char} ${charUtils.char2Unicode(item.char)} ${item.py}\n`;
      t += (is_zh ? item.def.cn : item.def.eg) + '\n';
      t += (is_zh ? item.def.eg : item.def.cn) + '\n';
      t += '\n';
    });
    navigator.clipboard.writeText(t);
    message.success(msg['home.copy_success'], 1.5);
  };

  return (
    <Modal
      title={msg['home.structures_in_lib']}
      open={open}
      onCancel={() => onCancel()}
      footer={null}
      width={isMobile ? '100%' : 680}
    >
      <div>
        <List
          dataSource={data}
          grid={{
            gutter,
          }}
          renderItem={(item) => {
            return (
              <List.Item>
                <StructItem
                  item={item}
                  onItemClick={onCancel}
                  size={size}
                />
              </List.Item>
            );
          }}
        />
      </div>
      <Row>
        <Link key="create" to={`/create?char=${char}`}>
          <Button
            // key="create"
            type="primary"
            size='small'
            // href={`/create?char=${char}`}
            onClick={() => onCancel()}
          >
            {/* Not found, create a new record */}
            {msg['home.structure_not_found']}
          </Button>
        </Link>
      </Row>
      <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 6, marginTop: 10 }}>
        {msg['home.text_search_result']} ({result.length})
        <Button
          type='link' size='small'
          onClick={() => {
            copyResultToClipboard();
          }}
        >
          {msg['common.copy']}
        </Button>
      </div>
      <List>
        <VirtualList
          data={result}
          height={isMobile ? 220 : 240}
          itemHeight={40}
          itemKey={"char"}
        >
          {({ char, def, py }, index) => (
            <List.Item key={index} style={{ fontFamily: FontFamily }}>
              <div>
                <Row align="middle">
                  <span style={{ paddingRight: 4 }}>{index + 1}.</span>
                  <span style={{
                    paddingRight: 6,
                    fontWeight: 'bold',
                    color: char === query ? '#A91D36' : '#000',
                  }}>{char}</span>
                  <Highlighter
                    style={{ paddingRight: 6 }}
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={charUtils.char2Unicode(char)}
                    highlightStyle={{ color: '#A91D36', backgroundColor: 'white', fontWeight: 'bold', padding: 0 }}
                  />
                  <span style={{ paddingRight: 6 }}>{py}</span>

                </Row>
                <Row align="middle" wrap style={{ marginLeft: 14 }}>
                  <Highlighter
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={is_zh ? def.cn : def.eg}
                    highlightStyle={{ color: '#A91D36', backgroundColor: 'white', fontWeight: 'bold', padding: 0 }}
                  />
                </Row>
                <Row align="middle" wrap style={{ marginLeft: 14 }}>
                  <Highlighter
                    style={{ whiteSpace: 'pre-wrap' }}
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={is_zh ? def.eg : def.cn}
                    highlightStyle={{ color: '#A91D36', backgroundColor: 'white', fontWeight: 'bold', padding: 0 }}
                  />
                </Row>
              </div>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </Modal>
  );
};

const SearchBar = () => {
  const { isMobile } = useWindowSize();

  const { progress } = useSelector((state) => state.global);

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exampleChar, setExampleChar] = useState('');

  const [data, setData] = useState([]);
  const Size = isMobile ? 70 : 85;

  const [exampleData, setExampleData] = useState({});
  const [textSearchResult, setTextSearchResult] = useState([]);
  const [query, setQuery] = useState('');
  const handleHeaderSearch = async (value) => {
    if (value === '' || !value) {
      return;
    }
    const valid_chars = charUtils.extractUniqueChars(value);

    if (valid_chars.length === 1) {
      setLoading(true);

      const char = valid_chars[0];

      let def = await resourceAPI.getDef({ char });
      let py = await resourceAPI.getPinyin({ char });
      if (def === null || def === undefined || py === null || py === undefined) {
        def = { eg: '', cn: '' };
        py = '';


        const username = localStorage.getItem('name');
        const digest = localStorage.getItem('digest');
        const lang = localStorage.getItem('umi_locale').toLowerCase();

        await resourceAPI.addMissingChar({ char, username, digest, lang });
        messageApi.info(msg['home.missing_info_1'] + char + msg['home.missing_info_2'], 5);

        setExampleChar('');
        setExampleData({});

        setQuery(char);
        setData([]);
        setTextSearchResult([]);

        setModalOpen(false);
        setLoading(false);
        return
      }

      const res = await searchAPI.char2records({ char })
      setExampleData({ char, def, py });
      setExampleChar(valid_chars[0]);
      setData(res);

      const textRes = await resourceAPI.definitionSearch({ text: valid_chars[0] });
      setTextSearchResult(textRes);

      setQuery(valid_chars[0]);

      setModalOpen(true);
      setLoading(false);


    } else {
      setLoading(true);
      let res = []

      let charRes = null;
      if (valid_chars.length > 0) {
        const charTmp = String.fromCharCode(parseInt(value, 16));
        charRes = await resourceAPI.getDef({ char: charTmp });
      }
      const text = value.length > 100 ? value.slice(0, 100) : value;
      const textRes = await resourceAPI.definitionSearch({ text });

      const regex = /^[a-zA-Z0-9-]+$/;
      if (regex.test(text)) {
        res = await searchAPI.idSearch({ query: text.toUpperCase() });
        // !TODO unicode substring search
      }

      if (charRes) {
        setExampleChar(charTmp);
        setExampleData({ char: charTmp, def: charRes });

      } else {
        setExampleChar('');
        setExampleData({});
      }

      setQuery(text);
      setData(res);
      setTextSearchResult(textRes);

      setModalOpen(true);
      setLoading(false);
    }
  };

  const intl = useIntl();
  const msg = intl.messages;
  const [messageApi, contextHolder] = message.useMessage();

  const MobileBarWidth = (document.documentElement.clientWidth - 36 - 78) * 0.9

  useEffect(() => {
    const element = document.getElementById('HeaderSearchBar');
    if (element) {
      element.style.height = isMobile ? '32px' : '40px';
      element.style.fontSize = isMobile ? '12px' : '14px';
    }
  }, [])
  return (
    <Row>
      {contextHolder}
      <SearchInput
        id="HeaderSearchBar"
        onSearch={handleHeaderSearch}
        size={isMobile ? 'middle' : 'large'}
        className="headInput"
        placeholder={msg['home.example_char']}
        loading={loading}
        style={{
          width: isMobile ? MobileBarWidth : 360,
          marginLeft: isMobile ? 20 : 0,
          marginTop: isMobile ? 4 : 2,
          fontFamily: fontConst.FontFamily,
        }}
      />
      <StructModal
        open={modalOpen}
        title={'Structure'}
        char={exampleChar}
        data={data}
        exampleData={exampleData}
        textSearchResult={textSearchResult}
        query={query}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        size={Size}
      />
      <LoadingBar
        color="#f11946"
        height={3}
        progress={progress}
      />
    </Row>
  );
};

export default SearchBar;
