import { request } from '@umijs/max';

export const getRecord = async (params, options) => {
  const url = '/api/record/get_record/' + params.record_id;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getRelated = async (params, options) => {
  const url = '/api/record/get_related';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const updateRelated = async (params, options) => {
  const url = '/api/record/update_related';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getWorkspaceRecord = async (params, options) => {
  const url = '/api/record/get_workspace_record/' + params.record_id + '/' + params.commit_id;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getRecordStruct = async (params, options) => {
  const url = '/api/record/get_record_struct/' + params.record_id;
  return request(url, {
    method: 'GET',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getStructHash = async (params, options) => {
  const url = '/api/record/get_struct_hash';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const createRecord = async (params, options) => {
  const url = '/api/record/create_record';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const pushLatest = async (params, options) => {
  const url = '/api/record/push_latest';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const deleteRecord = async (params, options) => {
  const url = '/api/record/delete_record';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const createCommit = async (params, options) => {
  const url = '/api/record/create_commit';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};


export const getAllCommits = async (params, options) => {
  const url = '/api/record/get_all_commits';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getCommit = async (params, options) => {
  const url = '/api/record/get_commit';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const replyCommit = async (params, options) => {
  const url = '/api/record/reply_commit';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};


export const reviewCommit = async (params, options) => {
  const url = '/api/record/review_commit';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const deleteCommit = async (params, options) => {
  const url = '/api/record/delete_commit';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getHistory = async (params, options) => {
  const url = '/api/record/get_history';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const getHistoryDetail = async (params, options) => {
  const url = '/api/record/get_history_detail';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const historySetLatest = async (params, options) => {
  const url = '/api/record/history_set_latest';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};

export const deleteHistory = async (params, options) => {
  const url = '/api/record/delete_history';
  return request(url, {
    method: 'POST',
    data: {
      ...params,
    },
    ...(options || {}),
  });
};
