export default {
    user: {
        lang: 'English',
        lang_success: 'Language updated successfully',
        lang_fail: 'Failed to update language',
        login: '登录',
        logout: '退出',
        clear: '清空',
        signup: '注册',
        username: '用户名',
        username_or_email: '用户名或邮箱',
        password: '密码',
        email: '邮箱',
        invalid_email: '无效邮箱',
        agreement: '协议',
        termOfService: '我已阅读并同意服务条款',
        please_agree_to: '请同意服务条款',
        register: '注册',
        forgot_pwd: '忘记密码',
        getValidationCodeSuccess: '验证码已发送',
        resetPwdSuccess: '密码重置成功',
        resetPwd: '重置密码',
        emailOrUsernameEmptyError: '用户名或邮箱不能为空',
        emailFormatError: '邮箱格式错误',
        username_or_email: '用户名或邮箱',
        getValidationCode: '获取验证码',
        validationCode: '验证码',
        newPassword: '新密码',
    },
    home: {
        copy_record_id: '复制记录ID',
        copy_success: '复制成功',
        ori_idea: '创意',
        JB: '包弫',
        impl: '实现',
        XC: '徐超',
        characters: '汉字',
        structures: '结构',
        users: '用户',
        walkthrough: '网站演示',
        intro: '输入包含您感兴趣的结构的样例汉字，\
            或英文单词, 或从下面选择一个结构。',
        structLib: '结构库',
        example_char: '样例汉字或语义（仅支持英文）',
        example_fail: '样例汉字无效，请输入一个带有目标结构的汉字',
        structures_in_lib: '数据库中的结构',
        structure_not_found: "如果没有目标结构，请点击这里",
        text_search_result: '文本搜索结果',
    },
    create: {
        title: '通过点击线条定义目标子结构',
        check_db: '检查数据库',
        similar_structures: '相似结构',
        no_hit: '没有找到，创建新记录'
    },
    record: {
        related_structures: '相关结构',
        no_data: '无',
        desp1: '汉字按含义排列。没有定义的汉字在最后。',
        desp2: '如果要搜索目标结构，或编辑当前结果，请点击右侧的编辑图标',
        edit: '编辑记录',
        copy_to_excel: '将Excel格式复制到剪贴板',
        save_to_excel: '保存到Excel',
        compare_characters: '比较两组汉字',
        groupA: 'A组',
        groupB: 'B组',
        common: '共同',
        uniqueA: 'A组独有',
        uniqueB: 'B组独有',
        duplicateA: 'A组重复',
        duplicateB: 'B组重复',
        merged: '合并',
        search: '搜索',
        search_placeholder: '对汉字和定义进行文本搜索',
        search_success: '搜索成功',
        rollback: '清除搜索，恢复到完整记录',
        edit: '编辑',
        compare: '比较两组汉字',
        scroll_to_top: '滚动到顶部',
        scroll_to_bottom: '滚动到底部',
    },
    workspace: {
        edit_structure: '编辑结构',
        clear_all_selected_edges: '清除所有选中的边',
        clear_invalid_edges: '清除所有无效边（虚线）',
        toggle_edges_region: '通过框选/点击改变边的状态',
        load_success: '成功加载',
        save_success: '成功保存',
        sort_def_by_alphabet: '按字母顺序排序定义',
        sort_def_by_alphabet_success: '成功按字母顺序排序定义',
        info: "信息",
        email: "联系我们",

        instruct: {
            "guidance": "使用指导",
            "i1": "通过选择或取消选择红线来改变目标结构",
            "i2": "点击“搜索”按钮开始搜索；完成需要5~8分钟",
            "i3": "搜索完成后，您可以选择“未分类”来查看结果",
            "i4": "当您找到想保留的结果时，选择它们，选择“已选”标签，然后选择“移动所选到”，选择“正面”",
            "i5": "最后一步是将正面结果保存为.tsv文件，保存在“保存/加载”标签下。您可以在Excel或Numbers中打开它们。如果您在Excel中导入它们，确保文件源设置为“Unicode (UTF-8)”",
            "i6": "您可以随时通过点击“保存/加载”标签下的“保存”或“加载”按钮来保存或加载您的进度"
        },
        font:{
            test: "字体测试",
            type: "字符类型",
            your_browser: "您的浏览器",
            test_desp: "如果您在上面的表格中看到方块或空白，说明您当前的系统不支持这些字符，您需要下载并安装以下的字体：",
            install: "安装字体 ",
            install_1: "下载并保存文件",
            install_2: "打开 hanazono-20170904.zip",
            install_3: "双击 HanaMinA.ttf 文件",
            install_4: "点击安装按钮",
            install_5: "双击 HanaMinB.ttf 文件",
            install_6: "点击安装按钮",
            install_7: "您可能需要重新启动您的浏览器（在某些情况下，需要重新启动操作系统）以使更改生效",
            install_8: "返回并确保所有内容都正确显示",
        },
        search: {
            paste: '查看特定汉字的结果',
            promote: '个汉字被移到了最前面',
            target: '目标结构',
            alter_structure: '请在左边输入需要对比的结构',
            select_page: '全选当前页',
            deselect_page: '取消选择当前页',
            select_group: '全选当前分组',
            deselect_group: '取消选择当前分组',
            total: '总计',
            chars: '个汉字',
            move_selected: '将选中的移动到',

            advanced_setting: '高级设置',
            bw: '搜索宽度',
            bwi: '搜索空间的大小，整数。 0 代表动态调整.',
            pt: '极化阈值',
            pti: '对角度错误的容忍度.',
            st: '骨架阈值',
            sti: '对骨架错误的容忍度.',
            post: '形状阈值',
            posti: '对形状错误的容忍度.',
            sm: '搜索模式',
            smi_highlighted: '仅在高亮的子结构上搜索。',
            smi_whole: '在整个图上搜索。',
            smi: '搜索的范围',
            load_preset: '加载预设配置（严格为默认选项）',
            strick: '严格',
            normal: '平衡',
            loose: '宽松',
            sort_param: '排序参数',
            nw: '节点权重',
            nwi: '节点差异在排序中的比例.',
            sw: '骨架权重',
            swi: '骨架差异在排序中的比例.',
            pw: '形状权重',
            pwi: '形状差异在排序中的比例.',
            resort: '重新排序',
            search_first: '请先搜索',
        },
        task:{
            info: '任务信息',
            create_fail: '创建任务失败: ',
            wait_in_line: '排队中，还有',
            task_before: '个任务在前面',
            running: '运行中',
            fetching: '获取结果中...',
            done:'完成! 现在你可以查看结果，或者重新排序了。',
            unknown: '未知状态',
            time_info: '搜索任务需要5~8分钟，您可以稍后再来查看结果。',
        },
        commit:{
            commit_success: '提交成功',
            commit_msg: '说明信息',
            admin_ops: '管理员操作',
        },
        sl: {
            push_latest: '推送为最新',
            push_success: '成功推送为最新',
            push_fail: '推送失败',
            load_json: '加载记录的json文件',
            save_json: '保存记录为json文件',
            save_to_excel: '保存正样本和待定到Excel',
        },
    },
    common: {
        positive: '正样本',
        undefined: '待定',
        unclassified: '未分类',
        operation: '操作',
        search: '搜索',
        selected: '选中的',
        commit: '提交',
        save_and_load: '保存/加载',
        login_first_parentheses: '(请先登录)',
        copy_all_excel: '复制所有为Excel格式',
    },

    about_us: {
        j1: "Jennifer Ball（包弫—Bao1 Zhen3—弫代表“有影响力，有力量”）\
        拥有加州大学洛杉矶分校的戏剧美术学士学位以及圣迭戈州立大学的创意写作硕士学位。\
        自2013年以来，她在苏州大学教授英语，教过453名学生。\
        她不仅带每一位学生出去吃过晚餐，还带许多学生去过美国和西班牙。\
        她还为一些学生安排了游泳课，现在他们中的几个已经学会了游泳。",
        j2: "Jennifer还向苏州大学的学生免费发放了600顶自行车安全头盔，以推广安全意识。\
        因为她认为女性哺乳动物是书面语言的根源，她从2009年开始学习书面中文和普通话（超过8年）。\
        她的网站www.OriginofAlphabet.com探讨了早期文化的语言数据。\
        Jennifer认为汉字是所有书面语言的关键。她雇佣了前学生徐超来为她构建一个汉字的子结构搜索引擎，\
        徐超在一年内完成了这项工作。他们现在正在为这个搜索引擎构建网站，进行演讲，并向期刊提交论文。",
        j3: "自1985年以来，Jennifer和她的丈夫，基因泰克研发部主管Dr. Michael Varney，\
        一起演奏法国号、小号、钢琴，并在摇滚乐队中演唱。\
        他们曾在Bonedells、Fluids、Free-Range Chickens乐队中演出，\
        现在则在Fingerpuppets乐队中演出，该乐队在2009年基因泰克乐队大战中获胜。\
        Jennifer还参加过六个电视游戏节目和一个关于游戏节目的纪录片。\
        Jennifer和Mike共同育有一女，Jordan，她从哈维·穆德学院获得计算机科学学位，\
        目前在加州大学戴维斯分校攻读心理学博士学位，并同时学习阿拉伯语。\
        他们的儿子Dalton在Pitzer毕业，从哈维·穆德学院获得计算机科学学位，\
        目前在加州大学圣迭戈分校攻读MBA，并在摇滚乐队Forty Feet North中演奏贝斯。",
        x1: "Xu Chao（徐超）拥有中国苏州大学纳米科学与技术学院的物理学学士学位。\
        毕业后，他花了一年半的时间研究有机记忆电容器，之后他的兴趣转向了计算机编程，\
        并开始与Jennifer Ball合作开发HanziFinder：一个汉字子结构搜索引擎。"
    },
    termOfService: {
        "title": "HanziFinder条款协议",
        "last_update": "最后更新：2023年11月11日",
        "t1": "条款接受",
        "p1": "通过访问或使用HanziFinder，您同意遵守并受以下服务条款协议（“协议”）的约束。如果您不同意这些条款，请不要使用服务。本协议是您与HanziFinder之间的法律约束合同。",
        "t2": "协议更改",
        "p2": "HanziFinder保留随时修改或修订本协议的权利，无需事先通知。您有责任定期审阅本协议以了解任何更改。您继续使用服务后的更改发布构成了您对这些更改的接受。",
        "t3": "服务描述",
        "p3": "HanziFinder是一个汉字子结构搜索引擎，允许用户根据汉字的结构组成和属性搜索和分析汉字和文本。",
        "t4": "用户注册",
        "p4": "尽管HanziFinder的某些功能可能无需注册即可访问，但某些服务或功能可能需要用户注册。注册时，您同意提供准确且最新的信息，并维护账户凭证的安全。您负责您账户下发生的所有活动。",
        "t5": "用户行为",
        "p5": "您同意按照所有适用的法律和法规使用HanziFinder。您同意不将汉字查找用于任何非法或未授权的目的。您同意不使用汉字查找违反您所在管辖区的任何法律（包括但不限于版权法）。",
        "t6": "知识产权",
        "p6": "HanziFinder及其内容，包括但不限于文本、图形、标志和软件，受版权和其他知识产权法律保护。未经我们明确许可，您不得复制、翻印、分发或基于汉字查找创建衍生作品。",
        "t7": "保证免责声明",
        "p7": "HanziFinder“按现状”和“按可用”基础提供，不提供任何种类的明示或暗示保证。我们不保证服务不会有错误或不中断，或者它将满足您的特定需求。",
        "t8": "责任限制",
        "p8": "在法律允许的最大范围内，HanziFinder及其关联方不对因使用服务或本协议而产生的任何直接、间接、偶然、特殊、后果性或惩罚性损害承担责任。",
        "t9": "终止",
        "p9": "HanziFinder保留随时因任何原因终止或暂停您访问服务的权利，无需事先通知。",
        "t10": "适用法律",
        "p10": "本协议应受加利福尼亚州法律的管辖并依据其解释。",
        "t11": "联系方式",
        "p11": "如果您对本协议有任何疑问或关注，请通过admin@hanzifinder.com与我们联系。使用HanziFinder，即表示您同意遵守本服务条款协议。如果您不同意这些条款，请不要使用服务。"
    }
};
