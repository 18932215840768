import Logo from '@/components/Header/Logo';
import HeaderUser from '@/components/Header/User';
import SearchBar from '@/components/Header/SearchBar';

export const request = {
  errorConfig: {
    errorHandler() { },
    errorThrower() { },
  },
  requestInterceptors: [
    (url, options) => {
      return {
        url: `${url}`,
        options: {
          ...options,
          interceptors: true,
          headers: {
            ...options.headers,
            username: localStorage.getItem('name'),
            digest: localStorage.getItem('digest'),
          },
        },
      };
    },
  ],
  responseInterceptors: [],
};

export async function getInitialState() {
  return { name: '@umijs/max' };
}

export const layout = () => {
  return {
    logo: () => <Logo />,
    layout: 'top',
    fixedHeader: true,
    menuRender: () => null,
    headerContentRender: () => <SearchBar />,
    rightRender: (initState) => <HeaderUser initState={initState} />,
  };
};
