import * as user from './user';
import * as graph from './graph';
import * as record from './record';
import * as search from './search';
import * as resource from './resource';

export default {
  user,
  graph,
  record,
  search,
  resource,
};
