export default {
  state: {
    username: '',
    progress: 0,
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
