import React, { useState, useEffect } from 'react';
import { Row, Input, Modal, List, Button, message, Divider } from 'antd';

import * as fontConst from '@/constants/font';
import * as searchAPI from '@/services/search';
import * as resourceAPI from '@/services/resource';
import * as charUtils from '@/utils/character';
import { Link, useIntl, history, useSelector } from '@umijs/max';

import Structure from '@/components/Structure';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import LoadingBar from 'react-top-loading-bar';

import './SearchBar.less';
import Highlighter from "react-highlight-words";
import api from '@/services';

import useWindowSize from '@/hooks/useWindowSize';
import * as randomUtils from '@/utils/random';

const SearchInput = Input.Search;
import { FontFamily } from '@/constants/font';

const StructItem = ({ item, onItemClick, size }) => {

  const intl = useIntl();
  const msg = intl.messages;

  const [messageApi, contextHolder] = message.useMessage();

  const uuid = 'home-struct-' + randomUtils.makeId(8);
  const { show } = useContextMenu({
    id: uuid,
  });

  const [struct, setStruct] = useState({
    xys: [],
    edges: [],
  });

  const getStruct = async () => {
    if (item.record_id === '') {
      setStruct({
        xys: [],
        edges: [],
      });
      return;
    }
    const res = await api.record.getRecordStruct({ record_id: item.record_id });
    setStruct(res);
  };

  useEffect(() => {
    getStruct();
  }, [item]);


  return (
    <div
      onClick={(e) => {
        e.nativeEvent.stopImmediatePropagation();
        if (onItemClick) {
          onItemClick();
        }
        history.push(`/record?id=${item.record_id}`);
      }}
    >
      {contextHolder}
      <div id={uuid}
        onContextMenu={(event) => {
          event.preventDefault();
          show({
            event,
            props: {
              key: 'value',
            },
          })
        }}>
        <a
          href={`/record?id=${item.record_id}`}
          onClick={(e) => e.preventDefault()}
        >
          <Structure struct={struct} size={size} />
        </a>

      </div>
      <Menu id={uuid}>
        <Item onClick={(e) => {
          navigator.clipboard.writeText(item.record_id);
          messageApi.success(msg['home.copy_success'] + ': ' + item.record_id, 1.5);
        }}>
          <div
            style={{ zIndex: 0 }}
          >
            {msg['home.copy_record_id']}
          </div>
        </Item>
      </Menu>
      {item.num !== undefined ?
        (<div
          style={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 8,
            fontSize: 10,
          }}
        >
          <span>{item.num}</span>
        </div>) : null
      }

    </div>
  )
};


const StructModal = ({ open,
  char, data, exampleData, textSearchResult, query,
  gutter = 8, size, onCancel }) => {
  const intl = useIntl();
  const msg = intl.messages;

  const { isMobile } = useWindowSize();

  const initResult = Object.keys(exampleData).length > 0 ? [exampleData] : [];
  const result = [...initResult, ...textSearchResult];

  return (
    <Modal
      title={msg['home.structures_in_lib']}
      open={open}
      onCancel={() => onCancel()}
      footer={null}
    >
      <div>
        <List
          dataSource={data}
          grid={{
            gutter,
          }}
          renderItem={(item) => {
            return (
              <List.Item>
                <StructItem
                  item={item}
                  onItemClick={onCancel}
                  size={size}
                />
              </List.Item>
            );
          }}
        />
      </div>
      <Row>
        <Link key="create" to={`/create?char=${char}`}>
          <Button
            // key="create"
            type="primary"
            size='small'
            // href={`/create?char=${char}`}
            onClick={() => onCancel()}
          >
            {/* Not found, create a new record */}
            {msg['home.structure_not_found']}
          </Button>
        </Link>
      </Row>
      <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 6, marginTop: 10 }}>
        {msg['home.text_search_result']} ({result.length})
        </div>
      <div style={{
        overflowY: 'scroll',
        maxHeight: 'calc(100vh - 460px)',
        width: '100%',
      }}>
        {result.map((item, index) => (
          <div key={index} style={{ fontFamily: FontFamily }}>
            <Row align="middle" wrap >
              <Highlighter
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={`${index + 1} ` + item.char + ' ' + charUtils.char2Unicode(item.char) + ' ' + item.py}
                highlightStyle={{ backgroundColor: '#ffc069' }}
              />
            </Row>
            <Row align="middle" wrap style={{ marginLeft: 12 }}>
              <Highlighter
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={item.def.eg}
                highlightStyle={{ backgroundColor: '#ffc069' }}
              />
            </Row>
            <Row align="middle" wrap style={{ marginLeft: 12 }}>
              <Highlighter
                style={{ whiteSpace: 'pre-wrap' }}
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={item.def.cn}
                highlightStyle={{ backgroundColor: '#ffc069' }}
              />
            </Row>
            <Divider style={{ margin: '5px 0' }} />
          </div>
        ))}
      </div>
    </Modal>
  );
};

const SearchBar = () => {
  const { isMobile } = useWindowSize();

  const { progress } = useSelector((state) => state.global);

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exampleChar, setExampleChar] = useState('');

  const [data, setData] = useState([]);
  const Size = 120;

  const [exampleData, setExampleData] = useState({});
  const [textSearchResult, setTextSearchResult] = useState([]);
  const [query, setQuery] = useState('');
  const handleHeaderSearch = async (value) => {
    const valid_chars = charUtils.extractUniqueChars(value);

    if (valid_chars.length === 1) {
      setLoading(true);

      const res = await searchAPI.char2records({ char: valid_chars[0] })
      setExampleChar(valid_chars[0]);
      setData(res);

      const def = await resourceAPI.getDef({ char: value });
      const py = await resourceAPI.getPinyin({ char: value });

      setExampleData({ char: valid_chars[0], def, py });

      const textRes = await resourceAPI.definitionSearch({ text: valid_chars[0] });
      setTextSearchResult(textRes);

      setQuery(valid_chars[0]);

      setModalOpen(true);
      setLoading(false);


    } else {
      setLoading(true);
      let res = []

      const charTmp = String.fromCharCode(parseInt(value, 16));
      const charRes = await resourceAPI.getDef({ char: charTmp });

      const text = value.length > 100 ? value.slice(0, 100) : value;
      const textRes = await resourceAPI.definitionSearch({ text });

      const regex = /^[a-zA-Z0-9-]+$/;
      if (regex.test(text)) {
        res = await searchAPI.idSearch({ query: text.toUpperCase() });
      }

      if (charRes) {
        setExampleChar(charTmp);
        setExampleData({ char: charTmp, def: charRes });

      } else {
        setExampleChar('');
        setExampleData({});
      }

      setQuery(text);
      setData(res);
      setTextSearchResult(textRes);

      setModalOpen(true);
      setLoading(false);
    }
  };

  const intl = useIntl();
  const msg = intl.messages;

  const MobileBarWidth = (document.documentElement.clientWidth - 36 - 78) * 0.9

  useEffect(() => {
    const element = document.getElementById('HeaderSearchBar');
    if (element) {
      element.style.height = isMobile ? '32px' : '40px';
      element.style.fontSize = isMobile ? '12px' : '14px';
    }
  }, [])
  return (
    <Row>
      <SearchInput
        id="HeaderSearchBar"
        onSearch={handleHeaderSearch}
        size={isMobile ? 'middle' : 'large'}
        className="headInput"
        placeholder={msg['home.example_char']}
        loading={loading}
        style={{
          width: isMobile ? MobileBarWidth : 360,
          marginLeft: isMobile ? 20 : 0,
          marginTop: isMobile ? 4 : 2,
          fontFamily: fontConst.FontFamily,
        }}
      />
      <StructModal
        open={modalOpen}
        title={'Structure'}
        char={exampleChar}
        data={data}
        exampleData={exampleData}
        textSearchResult={textSearchResult}
        query={query}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        size={Size}
      />
      <LoadingBar
        color="#f11946"
        height={3}
        progress={progress}
      />
    </Row>
  );
};

export default SearchBar;
