export const MaxH = 500;

export const Color = {
  canvas: 'white',
  unhighlighted: '#C0C0C0',
  unselected: 'black',
  selected: 'lightgreen',
  text: 'black',
};

export const NodeRadius = 2.8;
export const EdgeWidth = 2.5;

// graph canvas
export const CanvasSize = 500;
export const CanvasPad = 25;
export const CanvasBackgroudColor = 'grey';
export const CanvasNodeRadius = 4;
export const CanvasEdgeWidth = 6;
export const CanvasNodeColor = '#3333CC';
export const CanvasNodeHighLightColor = 'red';
export const CanvasEdgeColor = '#D2B48C';
export const CanvasDragColor = 'lightgreen';
export const CanvasTextColor = 'black';