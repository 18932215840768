import api from '@/services';
import { delay } from '@/utils/delay';
import { history } from 'umi';

const md5 = require('md5');

const wipe_userInfo_from_local_storage = () => {
  localStorage.removeItem('name');
  localStorage.removeItem('digest');
};

const write_userInfo_to_local_storage = (name, digest) => {
  localStorage.setItem('name', name);
  localStorage.setItem('digest', digest);
};

export default {
  state: {
    name: '_unknown',
    digest: '',
    email: '',
    role: 'guest',
    isAdmin: false,
    nickname: '',
    settings: {},
  },
  reducers: {
    logout(state, { payload }) {
      wipe_userInfo_from_local_storage();
      return {
        ...state,
        name: '_unknown',
        digest: '',
        role: 'guest',
        isAdmin: false,
        nickname: '',
        email: '',
      };
    },
    loginWithDigest(state, { payload }) {
      const { name, digest, isAdmin, nickname, settings, email } = payload;
      write_userInfo_to_local_storage(name, digest);

      return {
        ...state,
        name,
        digest,
        email,
        role: 'user',
        isAdmin: isAdmin,
        nickname,
        settings,
      };
    },
  },
  effects: {
    *loginWithPwd({ payload }, { put, call }) {
      yield put({
        type: 'global/onLoading',
      });
      yield call(delay, 10);

      const { username, password, target_url } = payload;
      const digest = md5(password);
      const res = yield call(api.user.login, { username, digest });
      const { success, msg } = res;

      if (success) {
        const { is_admin: isAdmin, nickname, settings, email, username: returnUsername } = res;
        yield put({
          type: 'loginWithDigest',
          payload: {
            name: returnUsername,
            digest,
            email,
            role: 'user',
            isAdmin,
            nickname,
            settings,
            target_url
          },
        });
      } else {
        alert(msg);
      }
      yield put({
        type: 'global/offLoading',
      });
    },
    *startup({ payload }, { put, call }) {
      yield put({
        type: 'global/onLoading',
      });
      yield call(delay, 10);

      const username = localStorage.getItem('name');
      const digest = localStorage.getItem('digest');

      if (username && digest && digest !== 'guest') {
        const res = yield call(api.user.login, {
          username,
          digest,
        });
        const { success, msg } = res;
        if (success) {
          const { is_admin: isAdmin, nickname, settings, email, username: returnUsername } = res;
          yield put({
            type: 'loginWithDigest',
            payload: {
              name: returnUsername,
              digest,
              email,
              role: 'user',
              isAdmin,
              nickname,
              settings,
            },
          });
        } else {
          alert(msg);
        }
      }
      yield put({
        type: 'global/offLoading',
      });
    },
    *register({ payload }, { put, call }) {
      yield put({
        type: 'global/onLoading',
      });
      yield call(delay, 10);

      const { username, password, email } = payload;
      const digest = md5(password);
      const { success, msg } = yield call(api.user.register, {
        username,
        digest,
        email,
      });

      if (success) {
        write_userInfo_to_local_storage(username, digest);
        window.location.href = '/';
      } else {
        alert(msg);
      }
      yield put({
        type: 'global/offLoading',
      });
    },
  },
};
