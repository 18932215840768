import { connect, history, useIntl, setLocale } from 'umi';
import { Row, Space, Button, Popover, message, Tooltip } from 'antd';
import { useMount } from 'ahooks';
import { MenuOutlined } from '@ant-design/icons';
import * as UserApi from '@/services/user';

import useWindowSize from '@/hooks/useWindowSize';
import { useEffect, useState } from 'react';

const HeaderUser = ({ name, nickname, role, isAdmin, dispatch }) => {
  const intl = useIntl();
  const msg = intl.messages;

  const [messageApi, contextHolder] = message.useMessage();

  const { isMobile } = useWindowSize();

  useMount(() => {
    dispatch({
      type: 'user/startup',
    });
  });

  const isLogin = role !== 'guest';
  const syncLanguage = async () => {
    if (isLogin) {
      const res = await UserApi.getLanguage();
      if (res.success === 1) {
        setLocale(res.language, false);
      }
    } else {
      // user browser's language as default
      const language = navigator.language || navigator.userLanguage;
      const inChina = language.includes('zh');
      setLocale(inChina ? 'zh-CN' : 'en-US', false);
    }
  };

  useEffect(() => {
    syncLanguage();
  }, [isLogin]);

  const gotoLogin = () => {
    history.push('/user/login');
  };

  const logout = () => {
    dispatch({
      type: 'user/logout',
    });
    history.push('/');
  };

  const switchLanguage = () => {
    const locale = intl.locale === 'en-US' ? 'zh-CN' : 'en-US';
    setLocale(locale, false);

    if (isLogin) {
      UserApi.updateLanguage({ language: locale }).then((res) => {
        if (res.success === 1) {
          messageApi.success(msg['user.lang_success']);
        } else {
          messageApi.error(msg['user.lang_fail']);
        }
      });
    }
  };

  const langText = () => {
    if (!isMobile) {
      return msg['user.lang']
    } else {
      if (intl.locale === 'en-US') {
        return '中'
      } else {
        return 'EN'
      }
    }

  };

  const logText = () => {
    return isLogin ? msg['user.logout'] : msg['user.login']
  };


  const [mobileOpen, setMobileOpen] = useState(false);
  const MobileTooltip = <Tooltip
    placement='bottomRight'
    arrow={false}
    open={mobileOpen}
    onOpenChange={setMobileOpen}
    color='white'
    overlayStyle={{
      marginRight: -35,
      marginTop: 4,
      width: 'auto',
    }}
    title={(
      <div
        style={{
          textAlign: 'right',
          paddingRight: 0,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <Row justify="end" algin="middle">
          <Button type="link" size='small'
            onClick={() => history.push('/admin')}
            style={{ paddingRight: 0 }}
          >
            {msg['user.go_to_admin']}
          </Button>
        </Row>
        <Row justify="end" algin="middle">
          <Button
            size="small"
            type={isLogin ? 'text' : 'link'}
            onClick={() => {
              const f = isLogin ? logout : gotoLogin
              setMobileOpen(false)
              f()
            }}
            style={{ paddingRight: 0 }}
          >
            {logText()}
          </Button>
        </Row>
      </div>
    )}>
    <MenuOutlined />
  </Tooltip>
  return (
    <div
      style={{
        marginRight: isMobile ? -5 : 24,
      }}
    >
      {contextHolder}
      <Row justify="end" algin="middle">
        <Space>
          <Button
            size='small'
            type='text'
            style={{ backgroundColor: 'transparent' }}
            onClick={switchLanguage}
          >
            {langText()}
          </Button>

          {isMobile ? null :
            isAdmin ?
              <Popover placement='bottom'
                title={
                  <div style={{ textAlign: 'right' }}>
                    <Button type="link" size='small'
                      onClick={() => history.push('/admin')}
                    >
                      {msg['user.go_to_admin']}
                    </Button>
                  </div>
                }
              >
                <span className="sider-username">{isLogin ? nickname + ' ' : ''}</span>
              </Popover>
              :
              <span className="sider-username">{isLogin ? nickname + ' ' : ''}</span>}

          <div
            style={{
              marginTop: isMobile ? 2 : 0,
            }}
          >
            {isMobile ? MobileTooltip
              : <Button
                size="small"
                type='text'
                onClick={isLogin ? logout : gotoLogin}
              >
                {' '}
                {logText()}
              </Button>
            }
          </div>
        </Space>
      </Row>
    </div>
  );
};

export default connect(({ user }) => ({
  ...user,
}))(HeaderUser);
