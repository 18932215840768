import { Image } from 'antd';
import logoImg from '@/assets/logo.ico';
import { history } from '@umijs/max';
import useWindowSize from '@/hooks/useWindowSize';

const Logo = () => {
  const { isMobile } = useWindowSize();
  return (
    <div
      onClick={() => {
        history.push('/');
      }}
    >
      <Image
        src={logoImg}
        preview={false}
        height={isMobile ? 42 : 45}
        style={{marginBottom: isMobile ? 6 : 8}}
      />
    </div>
  )
};

export default Logo;
