// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/root/app/Front/src/models/global';
import model_2 from '/root/app/Front/src/models/user';

export const models = {
model_1: { namespace: 'global', model: model_1 },
model_2: { namespace: 'user', model: model_2 },
} as const
