import { useMemo, useState } from 'react';
import { useDebounceEffect } from 'ahooks';

const useWindowSize = () => {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerWidth,
    });

    const isMobile = useMemo(() => window.innerWidth <= 768, [size.width]);
    const isCollapsed = useMemo(() => window.innerWidth <= 992, [size.width]);
    const [collapsed, setCollapsed] = useState(isMobile);

    useDebounceEffect(() => {
        const handleResize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, [],
        { wait: 0.1 });

    const pcCollapsed = useMemo(() => {
        if (isMobile) {
            return true;
        } else {
            return false;
        }
    }, [size.width]);

    return { ...size, isMobile, isCollapsed, collapsed, setCollapsed, pcCollapsed };
};

export default useWindowSize;